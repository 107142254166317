import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from "styled-components";

export const StyledSvg = styled.svg`
  width: 100%;
  height: 100%;
`;
export const BenefitImage = () => {
    const {t} = useTranslation();

    return (
        <StyledSvg xmlns="http://www.w3.org/2000/svg" width="984.064" height="984.304" viewBox="0 0 984.064 984.304">
            <g id="Group_6357" data-name="Group 6357" transform="translate(-23445.41 4683.653)">
                <g id="Group_6356" data-name="Group 6356" transform="translate(8837.821 14321.365) rotate(-43)">
                    <path id="Subtraction_9" data-name="Subtraction 9"
                          d="M.379,695.873H0V0H.379A353.507,353.507,0,0,1,35.953,1.8,348.836,348.836,0,0,1,70.5,7.069c11.208,2.294,22.427,5.178,33.344,8.574,10.793,3.357,21.548,7.293,31.967,11.7,10.321,4.365,20.554,9.295,30.414,14.651,9.791,5.319,19.442,11.182,28.687,17.428a350.428,350.428,0,0,1,51.493,42.486A350.431,350.431,0,0,1,288.891,153.4c6.246,9.246,12.11,18.9,17.428,28.687,5.356,9.859,10.286,20.092,14.651,30.414,4.406,10.417,8.343,21.172,11.7,31.967,3.4,10.916,6.28,22.135,8.574,33.344a348.857,348.857,0,0,1,5.273,34.546,353.535,353.535,0,0,1,1.8,35.574,353.55,353.55,0,0,1-1.8,35.575,348.88,348.88,0,0,1-5.273,34.547c-2.294,11.21-5.179,22.428-8.574,33.345-3.358,10.795-7.294,21.55-11.7,31.967-4.366,10.322-9.3,20.555-14.651,30.415-5.318,9.79-11.182,19.442-17.428,28.688a350.442,350.442,0,0,1-42.486,51.494,350.436,350.436,0,0,1-51.493,42.486c-9.245,6.246-18.9,12.109-28.687,17.428-9.861,5.357-20.094,10.286-30.414,14.651-10.417,4.406-21.173,8.343-31.967,11.7-10.916,3.4-22.135,6.28-33.344,8.574a348.782,348.782,0,0,1-34.546,5.272A353.5,353.5,0,0,1,.379,695.873Z"
                          transform="translate(23668.814 -2893.498) rotate(180)" fill="#4285f4"/>
                    <path id="Subtraction_11" data-name="Subtraction 11"
                          d="M348.313,348.314H0V0H.379A353.662,353.662,0,0,1,35.953,1.8,349.014,349.014,0,0,1,70.5,7.068c11.209,2.294,22.428,5.179,33.344,8.574,10.785,3.354,21.54,7.29,31.967,11.7,10.322,4.366,20.554,9.3,30.414,14.651,9.788,5.317,19.44,11.181,28.688,17.429a350.434,350.434,0,0,1,51.493,42.486A350.411,350.411,0,0,1,288.891,153.4c6.245,9.244,12.109,18.9,17.428,28.687,5.355,9.858,10.285,20.091,14.652,30.415,4.405,10.416,8.342,21.171,11.7,31.966,3.394,10.91,6.279,22.129,8.574,33.344a348.942,348.942,0,0,1,5.273,34.547,353.648,353.648,0,0,1,1.8,35.574v.378Z"
                          transform="translate(24017.127 -3241.812) rotate(90)" fill="#db4437"/>
                    <path id="Subtraction_13" data-name="Subtraction 13"
                          d="M348.313,348.314H0V0H.379A353.356,353.356,0,0,1,35.953,1.8,348.824,348.824,0,0,1,70.5,7.069c11.212,2.295,22.431,5.18,33.343,8.574,10.791,3.355,21.546,7.292,31.967,11.7,10.318,4.364,20.551,9.293,30.415,14.651,9.793,5.32,19.444,11.184,28.687,17.429A350.383,350.383,0,0,1,246.4,101.908,350.446,350.446,0,0,1,288.891,153.4c6.243,9.241,12.107,18.893,17.428,28.688,5.355,9.858,10.285,20.091,14.651,30.415,4.407,10.417,8.343,21.172,11.7,31.966,3.395,10.914,6.28,22.132,8.574,33.344a348.75,348.75,0,0,1,5.272,34.547,353.366,353.366,0,0,1,1.8,35.574v.378Z"
                          transform="translate(23668.813 -3589.749)" fill="#f4b400"/>
                </g>
                <text id="Haute_Qualité" data-name="Haute
Qualité" transform="translate(23745.002 -4176.5)" fill="#fff" font-size="70"
                      font-family="SFProText-Regular, SF Pro Text">
                    <tspan x="-98.164" y="0">{t("sections.about_us.why_choose_us.benefit-image.high")}</tspan>
                    <tspan x="-116.689" y="84">{t("sections.about_us.why_choose_us.benefit-image.quality")}</tspan>
                </text>
                <text id="Faible_Coût" data-name="Faible
Coût" transform="translate(24157.002 -4176.5)" fill="#fff" font-size="70" font-family="SFProText-Regular, SF Pro Text">
                    <tspan x="-97.139" y="0">{t("sections.about_us.why_choose_us.benefit-image.low")}</tspan>
                    <tspan x="-78.87" y="84">{t("cost")}</tspan>
                </text>
                <text id="Livraison_Rapide" data-name="Livraison
Rapide" transform="translate(23952.002 -4420.998)" fill="#fff" font-size="70"
                      font-family="SFProText-Regular, SF Pro Text">
                    <tspan x="-148.203" y="0">{t("sections.about_us.why_choose_us.benefit-image.fast-delivery-1")}</tspan>
                    <tspan x="-113.682" y="84">{t("sections.about_us.why_choose_us.benefit-image.ast-delivery-2")}</tspan>
                </text>
            </g>
        </StyledSvg>
    );
}