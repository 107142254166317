import styled from "styled-components";
import {Body} from "../../App.Style";
import {COLORS} from "../../utils/constants";

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem;
  padding: 0 4rem;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }
`;

export const GridItem = styled.div`
  border-radius: 1rem;
  background-color: ${COLORS.backgroundWhite};
  color: ${COLORS.light};
  padding: 2rem;
`;

export const Title = styled.h2`
  text-align: left;
`;

export const JustifiedBody = styled(Body)`
  text-align: justify;
`;
