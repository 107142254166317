import {Home} from './pages/Home/Home';
import {Navigation} from './components/Navigation/Navigation';
import {useEffect, useRef} from "react";

import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Aos from "aos";
import 'aos/dist/aos.css';
import {GlobalStyle} from "./App.Style";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: require('./locales/en/translation.json')
            },
            fr: {
                translation: require('./locales/fr/translation.json')
            }
        },
        lng: "fr",
        fallbackLng: "fr",
        interpolation: {
            escapeValue: false
        }
    });

export interface SectionRefObject {
    [key: string]: HTMLDivElement | null;
}

function App() {
    const sectionRefs = useRef<SectionRefObject>({});
    useEffect(() => {
        Aos.init({
            duration: 1000,
        });
    }, []);

    return (
        <>
            <GlobalStyle />
            <Navigation sectionRefs={sectionRefs}/>
            <header className="App-header">
                <Home sectionRefs={sectionRefs}/>
            </header>
        </>
    );
}

export default App;