import React, {RefObject, useEffect} from "react";
import {FullCompanyLogo, MiniCompanyLogo} from "../CompanyLogo/CompanyLogo";
import {ButtonGroup, NavBar, NavLinks, StyledNavButton} from "./Navigation.style";
import {SectionRefObject} from "../../App";
import {useTranslation} from "react-i18next";
import {LanguageSwitcher} from "../language/LanguageSwitcher";

interface NavigationProps {
    sectionRefs: RefObject<SectionRefObject>;
}

export const Navigation: React.FC<NavigationProps> = ({sectionRefs}) => {
    const {current} = sectionRefs;
    const [currentSection, setCurrentSection] = React.useState("section1");
    const {t} = useTranslation();

    const scrollToSection = (sectionId: string) => {
        if (current) {
            current[sectionId]?.scrollIntoView({behavior: 'smooth'});
        }
        setCurrentSection(sectionId);
    };
    useEffect(() => {
        const handleScroll = () => {
            if (sectionRefs.current) {
                const {section1, section2, section4, section5, section3} = sectionRefs.current;

                if (section5 && section5.getBoundingClientRect().top <= 200) {
                    setCurrentSection('section5');
                } else if (section4 && section4.getBoundingClientRect().top <= 200) {
                    setCurrentSection('section4');
                } else if (section3 && section3.getBoundingClientRect().top <= 200) {
                    setCurrentSection('section3');
                } else if (section2 && section2.getBoundingClientRect().top <= 200) {
                    setCurrentSection('section2');
                } else if (section1 && section1.getBoundingClientRect().top <= 200) {
                    setCurrentSection('section1');
                }
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [sectionRefs]);

    function isHomeSection() {
        return currentSection === 'section1'
    }

    return (
        <NavBar isHome={currentSection === 'section1'}>
            {isHomeSection()?<FullCompanyLogo/>:<MiniCompanyLogo/>}
            <NavLinks>
                <StyledNavButton
                    onClick={() => scrollToSection('section1')}
                    isSelected={currentSection === 'section1'}
                    isHome={isHomeSection()}>
                    {t("sections.home.title")}
                </StyledNavButton>
                <StyledNavButton
                    onClick={() => scrollToSection('section2')}
                    isSelected={currentSection === 'section2'}
                    isHome={isHomeSection()}>
                    {t("sections.about_us.title")}
                </StyledNavButton>
                <StyledNavButton
                    onClick={() => scrollToSection('section3')}
                    isSelected={currentSection === 'section3'}
                    isHome={isHomeSection()}>
                    {t("sections.services.title")}
                </StyledNavButton>
                <StyledNavButton
                    onClick={() => scrollToSection('section4')}
                    isSelected={currentSection === 'section4'}
                    isHome={isHomeSection()}>
                    {t("sections.blog-news.title")}
                </StyledNavButton>
                <StyledNavButton
                    onClick={() => scrollToSection('section5')}
                    isSelected={currentSection === 'section5'}
                    isHome={isHomeSection()}>
                    {t("sections.contact-us.title")}
                </StyledNavButton>
            </NavLinks>
            <ButtonGroup>
                <LanguageSwitcher/>
            </ButtonGroup>
        </NavBar>
    );
};