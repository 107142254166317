import styled from "styled-components";
import {COLORS} from "../../utils/constants";

export const StyledPrimaryButton = styled.button<{ mode: string }>`
  background-color: transparent;
  border: 2px solid ${props => props.mode === 'dark' ? COLORS.white : COLORS.black};
  border-radius: 4px;
  color: ${props => props.mode === 'dark' ? COLORS.white : COLORS.black};
  font-size: 14px;
  padding: 4px 24px;
  margin: 0 10px;

  &:hover {
    background-color: ${props => props.mode === 'dark' ? COLORS.white : COLORS.black};
    color: ${props => props.mode === 'dark' ? COLORS.black : COLORS.white};
    cursor: pointer;
  }
`;

export const StyledSecondaryButton = styled.button<{ mode: string }>`
  background-color: ${COLORS.applePrimary};
  border-radius: 4px;
  color: ${props => props.mode === 'dark' ? COLORS.white : COLORS.black};
  font-size: 14px;
  padding: 4px 24px;
  margin: 0 10px;

  &:hover {
    background-color: ${COLORS.appleSecondary};
    color: ${props => props.mode === 'dark' ? COLORS.white : COLORS.black};
    cursor: pointer;
  }
`;

export const StyledTextButton = styled.a`
  color: ${COLORS.primary};
  font-size: 1rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;