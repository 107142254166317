import styled, {css} from "styled-components";
import {Body, Caption2, Headline, Subhead} from "../../App.Style";

export const AboutUsTextsContainer = styled.div`
  flex: 1;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  width: 600px;
`;

export const AboutUsBodyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const AboutUsBody = styled(Caption2)<{ isOpen: boolean }>`
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({isOpen}) => isOpen && css`
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
  `};
`;

export const StyledAboutUsSectionText = styled.p`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
`;

export const AboutUsSubline = styled(Headline)<{ isOpen: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({isOpen}) => isOpen && css`
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
  `};
`;