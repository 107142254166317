import styled, { keyframes } from "styled-components";
import {COLORS} from "../../utils/constants";
import {Body, Headline} from "../../App.Style";

export const WelcomeContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const WelcomeImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
`;

export const CenteredContent = styled.p`
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const fadeIn = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const StyledTitle = styled(Headline)`
  animation: ${fadeIn} 0.5s;
`;

export const StyledText = styled(Body)`
  animation: ${fadeIn} 0.5s;
`;
