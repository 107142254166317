import React, {useEffect, useRef} from 'react';
import {
    MissionContainer,
    ContentWrapper,
    ServicesContainer,
    StyledButtonToolbar,
    StyledContainer,
    StyledIconButton,
    StyledSlider,
    TextBlock,
    ServiceTitleContainer,
    ServiceTitleImageContainer
} from "./Services.Style";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    FaChartBar,
    FaCloud,
    FaGavel,
    FaRobot,
    FaShieldAlt,
} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import {LuMonitorSmartphone} from "react-icons/lu";
import {PiCodeLight} from "react-icons/pi";
import {FaChalkboardUser} from "react-icons/fa6";
import Slider from "react-slick";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import "rsuite/dist/rsuite.min.css";
import {Body, Caption2, Headline, Subhead} from "../../App.Style";
import {Objectives} from "./Objectives";
import {AnimatedTitle} from "../../components/title/AnimatedTitle";
import image_services from "./images/hand_holding_phone.svg";
import image_services_placeholder from "./images/hand_holding_phone.placeholder.svg";
import {COLORS} from "../../utils/constants";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {StyledTextButton} from "../../components/button/Button.Style";

export const Services: React.FC = () => {
    const {t} = useTranslation();
    const slider = useRef<Slider | null>(null);
    useEffect(() => {
        if (slider.current) {
            slider.current.slickNext = slider.current.slickNext.bind(slider.current);
            slider.current.slickPrev = slider.current.slickPrev.bind(slider.current);
        }
    }, []);

    const next = () => {
        if (slider.current) {
            slider.current.slickNext();
        }
    };

    const previous = () => {
        if (slider.current) {
            slider.current.slickPrev();
        }
    };

    const sliderSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
    };

    return (
        <>
            <MissionContainer>
                <AnimatedTitle title={t('sections.services.title')} color={COLORS.white}/>
                <br/>
                <ServiceTitleContainer>
                    <TextBlock>
                        <Body color={COLORS.white}>OUR MISSION</Body>
                        <br/>
                        <Headline color={COLORS.white}>The Future of Banking</Headline>
                        <Caption2 color={COLORS.white}>
                            {t("sections.services.introduction")}
                        </Caption2>
                    </TextBlock>
                    <ServiceTitleImageContainer>
                        <
                            LazyLoadImage
                            src={image_services}
                            alt={'hand holding phone'}
                            effect="fade-in"
                            placeholderSrc={image_services_placeholder}
                        />
                    </ServiceTitleImageContainer>
                </ServiceTitleContainer>
            </MissionContainer>
            <ServicesContainer>
                <br/>
                <br/>
                <ContentWrapper>
                    <div style={{
                        width: '100%',
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center"
                    }}>
                        <Headline color={COLORS.black}> Banking services transformation</Headline>
                        <br/>
                        <Subhead color={COLORS.black}> Nous Concevons, Nous Realisons, Nous Accompagnons</Subhead>
                        <StyledTextButton onClick={() => {
                        }}>Contact us now<IoIosArrowForward size={"17px"}/></StyledTextButton>
                    </div>
                    <br/>
                    <StyledSlider ref={slider} {...sliderSettings}>
                        {services.map((item, index) => (
                            <StyledContainer key={index}>
                                {item.icon}
                                <br/>
                                <Headline color={COLORS.light}>{t(item.headline)}</Headline>
                                <br/>
                                <Body color={COLORS.light}>{t(item.body)}</Body>
                            </StyledContainer>
                        ))}
                    </StyledSlider>
                    <br/>
                    <StyledButtonToolbar>
                        <StyledIconButton onClick={previous} icon={<IoIosArrowBack size={"17px"}/>} circle/>
                        <StyledIconButton onClick={next} icon={<IoIosArrowForward size={"17px"}/>} circle/>
                    </StyledButtonToolbar>
                </ContentWrapper>
                <br/>
                <br/>
                <br/>
                <br/>
                <div style={{
                    width: '100%',
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center"
                }}>
                    <Headline color={COLORS.black}> You get the most value out of your Bank</Headline>
                    <br/>
                    <Subhead color={COLORS.black}> plus de client, moins de depenses, haute Croissance</Subhead>
                    <StyledTextButton onClick={() => {
                    }}>Contact us now<IoIosArrowForward size={"17px"}/></StyledTextButton>
                </div>
                <br/>
                <ContentWrapper>
                    <Objectives/>
                </ContentWrapper>
                <br/>
                <br/>
            </ServicesContainer>
        </>
    );
};

interface IService {
    headline: string;
    body: string;
    icon: React.ReactNode;
}

const services: IService[] = [
    {
        headline: "sections.services.services.digital-transformation-consulting.headline",
        body: "sections.services.services.digital-transformation-consulting.body",
        icon: <PiCodeLight size={40}/>,
    },
    {
        headline: "sections.services.services.mobile-banking-solutions.headline",
        body: "sections.services.services.mobile-banking-solutions.body",
        icon: <LuMonitorSmartphone size={40}/>,
    },
    {
        headline: "sections.services.services.data-analytics.headline",
        body: "sections.services.services.data-analytics.body",
        icon: <FaChartBar size={40}/>,
    },
    {
        headline: "sections.services.services.cybersecurity-services.headline",
        body: "sections.services.services.cybersecurity-services.body",
        icon: <FaShieldAlt size={40}/>,
    },
    {
        headline: "sections.services.services.ai-and-machine-learning.headline",
        body: "sections.services.services.ai-and-machine-learning.body",
        icon: <FaRobot size={40}/>,
    },
    {
        headline: "sections.services.services.regulatory-compliance-solutions.headline",
        body: "sections.services.services.regulatory-compliance-solutions.body",
        icon: <FaGavel size={40}/>,
    },
    {
        headline: "sections.services.services.cloud-migration.headline",
        body: "sections.services.services.cloud-migration.body",
        icon: <FaCloud size={40}/>,
    },
    {
        headline: "sections.services.services.customer-experience-design.headline",
        body: "sections.services.services.customer-experience-design.body",
        icon: <FaChalkboardUser size={40}/>,
    }
]