import styled from 'styled-components';

interface StyledSectionProps {
    fullHeight?: boolean;
}

export const StyledSection = styled.section<StyledSectionProps>`
  width: 100vw;
  height: ${({ fullHeight }) => (fullHeight ? '100vh' : 'auto')};
  background-color: #101010;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;