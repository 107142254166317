import {
    AboutUsBody,
    AboutUsBodyContainer,
    AboutUsSubline, StyledAboutUsSectionText,
} from "./AboutUsSectionText.Style";
import {StyledTextButton} from "../../components/button/Button.Style";
import {Body, Headline} from "../../App.Style";
import {COLORS} from "../../utils/constants";
import React from "react";

export type AboutUsSectionData = {
    headline: string;
    body: string;
    subline: string;
    image: JSX.Element;
};

export const AboutUsSectionText: React.FC<{
    section: AboutUsSectionData;
    isOpen: boolean;
    onClick: () => void;
}> = ({section, isOpen, onClick}) => {
    return (
        <StyledAboutUsSectionText>
            <Body color={COLORS.primary}>{section.headline}</Body>
            <br/>
            <AboutUsSubline color={COLORS.textHeadline} isOpen={isOpen}>{section.subline}</AboutUsSubline>
            <AboutUsBodyContainer>
                <AboutUsBody color={COLORS.caption2} isOpen={isOpen}>
                    {section.body}
                </AboutUsBody>
                {!isOpen && <StyledTextButton onClick={onClick}>more</StyledTextButton>}
            </AboutUsBodyContainer>
        </StyledAboutUsSectionText>
    );
};
