export const COLORS = {
    primary: '#ffa31a', // Blue: Use this as the main color of your brand. It determines the overall look and feel of your product. Use it in many areas of the interface to showcase brand identity.
    secondary: '#D2D2D7A3', // Gray: This is your accent color. Use it sparingly to highlight specific UI elements, such as promoted buttons. It complements the primary color.
    secondary_light: '#E3E3E8B3',
    success: '#2DA550', // Green: This is a semantic color that communicates success. Use it to indicate when an operation or task has been successfully completed.
    danger: '#FF3B30', // Red: This is another semantic color that is typically used to indicate danger or an error. Use it for error messages and to draw attention to areas that require user attention.
    warning: '#FFC107', // Yellow: This semantic color is often used to indicate warnings or to draw attention to important information.
    info: '#16C7A3', // Teal: This color can be used to provide information to users. It's a good choice for informational messages or statuses.
    light: '#1A1C1F', // Light gray: Use this neutral color for backgrounds or written text. It's a good choice for areas that require less attention and contrast with other elements.
    dark: '#1C1C1E', // Dark gray: This is another neutral color that can be used for text or backgrounds. It is also a good choice for borders or shadows to provide depth and contrast.
    white: '#FFFFFF', // White: This neutral color is commonly used for backgrounds, text, and UI elements. It provides maximum contrast with other colors and is excellent for readability.
    black: '#000000', // Black: Another neutral color, black is often used for text because of its high readability. It can also be used for UI elements and backgrounds in dark theme designs.
    applePrimary: '#1D1D1F', // Dark Gray: This is the primary color of the Apple website in dark mode. Use it for backgrounds and large surface areas in dark mode designs.
    appleSecondary: '#2C2C2E', // Darker Gray: This color is used by Apple in dark mode for secondary elements and backgrounds. Use it to create contrast with the primary color.
    appleTertiary: '#3A3A3C', // Even Darker Gray: This color is used by Apple in dark mode for tertiary elements and backgrounds. Use it to create additional contrast.
    appleSystemBlue: '#0A84FF', // System Blue: This color is used by Apple in dark mode for interactive and highlighted elements.
    appleSystemRed: '#FF453A', // System Red: This color is used by Apple in dark mode for destructive actions and error states.
    appleSystemGreen: '#32D74B', // System Green: This color is used by Apple in dark mode for success states and positive actions.
    appleSystemYellow: '#FFD60A', // System Yellow: This color is used by Apple in dark mode for warnings and alerts.
    error: '#FF3B30', // Red used by Apple in dark mode for error states.
    backgroundWhite: '#f5f5f7',
    textHeadline:'#1D1D1F',
    caption2:'#6E6E73'

};


