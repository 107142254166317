import {HomeContainer, StyledSection} from "./Home.Style";
import React, {RefObject} from "react";
import {SectionRefObject} from "../../App";
import {AboutUs} from "../about_us/AboutUs";
import {Services} from "../services/Services";
import {ContactUS} from "../contact_us/ContactUs";
import {DigitalBanking} from "../blog_news/DigitalBanking";
import {Welcome} from "./Welcome";

interface HomeProps {
    sectionRefs: RefObject<SectionRefObject>;
}

export const Home: React.FC<HomeProps> = ({sectionRefs}) => {
    return (
        <HomeContainer>
            <StyledSection fullHeight id="section1" ref={(el: HTMLDivElement) => {
                if (sectionRefs.current) sectionRefs.current['section1'] = el;
            }}>
                <Welcome/>
            </StyledSection>
            <br/>
            <StyledSection id="section2" ref={(el: HTMLDivElement) => {
                if (sectionRefs.current) sectionRefs.current['section2'] = el;
            }}>
                <AboutUs/>
            </StyledSection>
            <br/>
            <StyledSection id="section3" ref={(el: HTMLDivElement) => {
                if (sectionRefs.current) sectionRefs.current['section3'] = el;
            }}>
                <Services/>
            </StyledSection>
            <br/>
            <StyledSection id="section4" ref={(el: HTMLDivElement) => {
                if (sectionRefs.current) sectionRefs.current['section4'] = el;
            }}>
                <DigitalBanking/>
            </StyledSection>
            <br/>
            <StyledSection id="section5" ref={(el: HTMLDivElement) => {
                if (sectionRefs.current) sectionRefs.current['section5'] = el;
            }}>
                <ContactUS/>
            </StyledSection>
        </HomeContainer>
    );
};