import styled from "styled-components";


export const ContactUsContainer = styled.div`
  background-color: black;
  height: auto;
  width: 100%;
`;
export const ContactUsContentContainer = styled.div`
  height: auto;
  display: flex;
  width: 100%;
  padding: 64px;
  flex-direction: row;
  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
\`  ;
`;

export const FootnoteContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
