import React from "react";
import {ContactUsContainer, ContactUsContentContainer, FootnoteContainer} from "./ContactUs.Style";
import {AnimatedTitle} from "../../components/title/AnimatedTitle";
import {useTranslation} from "react-i18next";
import {ContactForm} from "./ContactForm";
import {ContactDetails} from "./ContactDetails";
import {COLORS} from "../../utils/constants";
import {
    MissionContainer,
    ServiceTitleContainer,
    ServiceTitleImageContainer,
    TextBlock
} from "../services/Services.Style";
import {Body, Caption2, Footnote, Headline, Subhead} from "../../App.Style";
import {LazyLoadImage} from "react-lazy-load-image-component";
import image_digital_banking from "../blog_news/images/send_money_everywhere.svg";
import {StyledTextButton} from "../../components/button/Button.Style";
import {IoIosArrowForward} from "react-icons/io";

export const ContactUS = () => {
    const {t} = useTranslation();

    return (
        <ContactUsContainer>
            <MissionContainer>
                <AnimatedTitle title={t('sections.contact-us.title')} color={COLORS.white}/>
                <br/>
                <ServiceTitleContainer>
                    <TextBlock>
                        <Body color={COLORS.white}>OUR MISSION</Body>
                        <br/>
                        <Headline color={COLORS.white}>The Future of Banking</Headline>
                        <Caption2 color={COLORS.white}>
                            {t("sections.services.introduction")}
                        </Caption2>
                    </TextBlock>
                    <ContactForm/>
                </ServiceTitleContainer>
            </MissionContainer>
            <br/><br/><br/><br/><br/>
            <FootnoteContainer>
                <Footnote color={COLORS.white}>Digital Banking Solution © 2023 | All rights reserved</Footnote>
            </FootnoteContainer>
        </ContactUsContainer>
    );
};