import styled from "styled-components";
import { ReactComponent as MiniLogo } from '../../assets/logo/mini_logo.svg';
import { ReactComponent as FullLogo } from '../../assets/logo/full_logo.svg';
import {COLORS} from "../../utils/constants";

export const StyledMiniLogo = styled(MiniLogo)`
  height: 24px;
  width: 24px;
`;

export const MiniLogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  color:${COLORS.white};
  font-family: 'SF Pro Display', sans-serif;
  font-weight: 600;
  font-size: 20px;
`;

export const StyledFullLogo = styled(FullLogo)`
  height: 50px;
  width: 100px;
`;

export const FullLogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  color:${COLORS.white};
  font-family: 'SF Pro Display', sans-serif;
  font-weight: 600;
  font-size: 20px;
`;