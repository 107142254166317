import {useState, FC} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import emailService from '@emailjs/browser';
import {
    ContactFormContainer, GroupedButtonRow, StyledEmailAndError,
    StyledErrorMessage,
    StyledForm,
    StyledFormInput, StyledFormNameAndEmail,
    StyledFormRow, StyledNameAndError,
    StyledTextArea
} from "./ContactForm.Style";
import {StyledPrimaryButton, StyledSecondaryButton} from "../../components/button/Button.Style";

interface AlertInfo {
    display: boolean;
    message: string;
    type: string;
}

interface FormData {
    name: string;
    email: string;
    subject: string;
    message: string;
}

export const ContactForm: FC = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm<FormData>();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [alertInfo, setAlertInfo] = useState<AlertInfo>({
        display: false,
        message: '',
        type: '',
    });

    const toggleAlert = (message: string, type: string): void => {
        setAlertInfo({display: true, message, type});

        setTimeout(() => {
            setAlertInfo({display: false, message: '', type: ''});
        }, 5000);
    };

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        const {name, email, subject, message} = data;
        try {
            setDisabled(true);

            const templateParams = {
                name,
                email,
                subject,
                message,
            };
            //TODO remove credentials from here
            await emailService.send(
                "service_8ryzskn",
                "template_z7m78kq",
                templateParams,
                "fjkMQB5Ro6QeaXAVR",
            );

            toggleAlert('Form submission was successful!', 'success');
        } catch (e) {
            console.error(e);
            toggleAlert('Uh oh. Something went wrong.', 'danger');
        } finally {
            setDisabled(false);
            reset();
        }
    };

    return (
        <ContactFormContainer>
            <StyledForm
                id='contact-form'
                onSubmit={handleSubmit(onSubmit)}
                noValidate
            >
                <StyledFormNameAndEmail>
                    <StyledNameAndError>
                        <StyledFormInput
                            hasError={!!errors.name}
                            errorMessage={errors.name?.message}
                            type='text'
                            {...register('name', {
                                required: {
                                    value: true,
                                    message: 'Please enter your name',
                                },
                                maxLength: {
                                    value: 30,
                                    message: 'Please use 30 characters or less',
                                },
                            })}
                            className='form-control formInput'
                            placeholder='Name'
                        ></StyledFormInput>
                        {errors.name && (
                            <StyledErrorMessage>{errors.name.message}</StyledErrorMessage>
                        )}
                    </StyledNameAndError>
                    <StyledEmailAndError>
                        <StyledFormInput
                            hasError={!!errors.email}
                            errorMessage={errors.email?.message}
                            type='email'
                            {...register('email', {
                                required: true,
                                pattern:
                                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            })}
                            className='form-control formInput'
                            placeholder='Email address'
                        ></StyledFormInput>
                        {errors.email && (
                            <StyledErrorMessage>
                                Please enter a valid email address
                            </StyledErrorMessage>
                        )}
                    </StyledEmailAndError>
                </StyledFormNameAndEmail>
                <StyledFormRow>
                    <StyledFormInput
                        hasError={!!errors.subject}
                        errorMessage={errors.subject?.message}
                        type='text'
                        {...register('subject', {
                            required: {
                                value: true,
                                message: 'Please enter a subject',
                            },
                            maxLength: {
                                value: 75,
                                message: 'Subject cannot exceed 75 characters',
                            },
                        })}
                        className='form-control formInput'
                        placeholder='Subject'
                    ></StyledFormInput>
                    {errors.subject && (
                        <StyledErrorMessage>
                            {errors.subject.message}
                        </StyledErrorMessage>
                    )}
                </StyledFormRow>
                <StyledFormRow>
                    <StyledTextArea
                        hasError={!!errors.message}
                        rows={3}
                        {...register('message', {
                            required: true,
                        })}
                        className='form-control formInput'
                        placeholder='Message'
                    ></StyledTextArea>
                    {errors.message && (
                        <StyledErrorMessage>Please enter a message</StyledErrorMessage>
                    )}
                </StyledFormRow>

                <GroupedButtonRow>
                    <StyledPrimaryButton mode={"dark"}
                                         className='submit-btn btn btn-primary'
                                         disabled={disabled}
                                         type='submit'
                    >
                        Send Now
                    </StyledPrimaryButton>
                    <StyledSecondaryButton onClick={() => {
                    }} mode={"dark"}>
                        Write Email
                    </StyledSecondaryButton>
                </GroupedButtonRow>
            </StyledForm>
            {alertInfo.display && (
                <div
                    className={`alert alert-${alertInfo.type} alert-dismissible mt-5`}
                    role='alert'
                >
                    {alertInfo.message}
                    <StyledPrimaryButton mode={"dark"}
                                         type='button'
                                         className='btn-close'
                                         data-bs-dismiss='alert'
                                         aria-label='Close'
                                         onClick={() =>
                                             setAlertInfo({display: false, message: '', type: ''})
                                         } // TODO Clear the alert when close button is clicked
                    ></StyledPrimaryButton>
                </div>
            )}
        </ContactFormContainer>
    );
};