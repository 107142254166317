import { FaGlobe } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import {LanguageButton} from "./LanguageSwitcher.Style";

export const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const switchLanguage = () => {
        i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en');
    };

    return (
        <LanguageButton onClick={switchLanguage}>
            <FaGlobe />
            {i18n.language.toUpperCase()}
        </LanguageButton>
    );
};
