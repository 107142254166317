import {FullLogoContainer, MiniLogoContainer, StyledFullLogo, StyledMiniLogo} from "./CompanyLogo.Style";
import React from "react";

export const MiniCompanyLogo = () => {
    return (
        <MiniLogoContainer>
            <StyledMiniLogo/>DBS
        </MiniLogoContainer>
    )
}

export const FullCompanyLogo = () => {
    return (
        <FullLogoContainer>
            <StyledFullLogo/>
        </FullLogoContainer>
    )
}

