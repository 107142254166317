import React, {useState} from "react";
import {
    AboutUsContent,
    StyledAboutUsSectionImage, AboutUsContainer, AboutUsBackground
} from "./AboutUs.Style";
import {AboutUsSectionData, AboutUsSectionText} from "./AboutUsSectionText";
import {useTranslation} from "react-i18next";
import bankingAppMockup from "./images/banking_app_mockup.svg";
import worldMap from "./images/world_map.svg";
import {BenefitImage} from "./images/Benefit_image";
import {AnimatedTitle} from "../../components/title/AnimatedTitle";
import { AboutUsTextsContainer } from "./AboutUsSectionText.Style";
import {COLORS} from "../../utils/constants";

export const AboutUs = () => {
    const {t} = useTranslation();
    const [openIndex, setOpenIndex] = useState(0);

    const initialSections: AboutUsSectionData[] = [
        {
            headline: t("sections.about_us.what_we_do.headline"),
            body: t("sections.about_us.what_we_do.body"),
            subline: t("sections.about_us.what_we_do.subline"),
            image: <img src={bankingAppMockup} alt="banking App Mockup"/>,
        },
        {
            headline: t("sections.about_us.who_are_we.headline"),
            body: t("sections.about_us.who_are_we.body"),
            subline: t("sections.about_us.who_are_we.subline"),
            image: <img src={worldMap} alt="worl dMap"/>,
        },
        {
            headline: t("sections.about_us.why_choose_us.headline"),
            body: t("sections.about_us.why_choose_us.body"),
            subline: t("sections.about_us.why_choose_us.subline"),
            image: <BenefitImage/>,
        },
    ];

    const handleClick = (index: number) => {
        if (openIndex === index) {
            setOpenIndex(-1);
        } else {
            setOpenIndex(index);
        }
    };

    return (
        <AboutUsBackground>
            <AboutUsContainer>
                <AnimatedTitle color={COLORS.applePrimary} title={t('sections.about_us.title')}/>
                <AboutUsContent>
                    <AboutUsTextsContainer>
                        {initialSections.map((section, index) => (
                            <div key={index}>
                                <br/>
                                <br/>
                                <AboutUsSectionText
                                    section={section}
                                    isOpen={openIndex === index}
                                    onClick={() => handleClick(index)}
                                />
                            </div>
                        ))}
                    </AboutUsTextsContainer>
                    <StyledAboutUsSectionImage>
                        {initialSections[openIndex]?.image && initialSections[openIndex]?.image}
                    </StyledAboutUsSectionImage>
                </AboutUsContent>
            </AboutUsContainer>
        </AboutUsBackground>
    );
};