import image_revenue from "./images/service_increase_revenue.jpeg";
import image_security from "./images/service_enhance_security.jpg";
import image_growth from "./images/service_growth.png";
import {useTranslation} from "react-i18next";
import {ObjectivesContainer, ObjectivesContent, StyledCol, StyledRow} from "./Objectives.Style";
import {Caption1, Caption2} from "../../App.Style";
import {COLORS} from "../../utils/constants";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import React from "react";

interface TextDescriptionProps {
    headline: string;
    body: string;
    paddingPosition: string;
}

const AnimatedText: React.FC<TextDescriptionProps> = ({headline, body, paddingPosition}) => {
    return (
        <Slide up>
            <Fade>
                <div style={{
                    textAlign: 'left',
                    paddingLeft: paddingPosition === 'left' ? '60px' : '0px',
                    paddingRight: paddingPosition === 'right' ? '40px' : '0px'
                }}>
                    <Caption1 color={COLORS.black}>{headline}</Caption1>
                    <br/>
                    <br/>
                    <Caption2 color={COLORS.black}>{body}</Caption2>
                </div>
            </Fade>
        </Slide>
    );
}

interface AnimatedImageProps {
    src: string;
    alt: string;
}

const AnimatedImage: React.FC<AnimatedImageProps> = ({src, alt}) => {
    return (
        <Slide up>
            <Fade>
                <img
                    src={src} alt={alt}
                />
            </Fade>
        </Slide>
    );
}

export const Objectives = () => {
    const {t} = useTranslation();

    return (
        <ObjectivesContainer>
            <ObjectivesContent>
                <StyledRow>
                    <StyledCol>
                        <AnimatedImage src={image_security} alt={"security"}/>
                    </StyledCol>
                    <StyledCol>
                        <AnimatedText headline={t("sections.services.objectives.security.headline")}
                                      body={t("sections.services.objectives.security.body")} paddingPosition={"left"}/>
                    </StyledCol>
                </StyledRow>

                <StyledRow>
                    <StyledCol>
                        <AnimatedText headline={t("sections.services.objectives.revenue.headline")}
                                      body={t("sections.services.objectives.revenue.body")} paddingPosition={"right"}/>
                    </StyledCol>
                    <StyledCol>
                        <AnimatedImage src={image_revenue} alt={"revenue"}/>
                    </StyledCol>
                </StyledRow>

                <StyledRow>
                    <StyledCol>
                        <AnimatedImage src={image_growth} alt={"growth"}/>
                    </StyledCol>
                    <StyledCol>
                        <AnimatedText headline={t("sections.services.objectives.growth.headline")}
                                      body={t("sections.services.objectives.growth.body")} paddingPosition={"left"}/>
                    </StyledCol>
                </StyledRow>
            </ObjectivesContent>
        </ObjectivesContainer>
    );
};