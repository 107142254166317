import styled from 'styled-components';
import Slider from "react-slick";
import IconButton from "rsuite/IconButton";
import "rsuite/dist/rsuite.min.css";
import {COLORS} from "../../utils/constants";

export const MissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${COLORS.black};
  width: 100%;
`;

export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${COLORS.white};
  height: auto;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: block;
  justify-content: space-between;
  width: 100%;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  border-radius: 1rem;
  background-color: ${COLORS.backgroundWhite};
  height: 300px;
  color: ${COLORS.light};
  padding: 2rem;
`;

export const StyledSlider = styled(Slider)`
  .slick-slide > div {
    margin-left: 64px;
  }
`;

export const StyledButtonToolbar = styled.div`
  display: flex;
  justify-content: right;
  padding: 0 4rem;
`;

export const StyledIconButton = styled(IconButton)`
  margin: 0 10px;
  background-color: ${COLORS.secondary};
  color: ${COLORS.light};

  &:hover {
    background-color: ${COLORS.secondary_light};
  }

  &:focus {
    background-color: ${COLORS.secondary};
  }
`;

export const ServiceTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 4rem;
  flex: 1;
`;

export const ServiceTitleImageContainer = styled.div`
  max-width: 60%;
`;