import {useTranslation} from "react-i18next";
import {Body, Caption2, Headline} from "../../App.Style";
import {COLORS} from "../../utils/constants";
import React from "react";
import {GridContainer, GridItem, JustifiedBody} from "./DigitalBanking.Style";
import {
    MissionContainer,
    ServiceTitleContainer,
    ServiceTitleImageContainer,
    TextBlock
} from "../services/Services.Style";
import {LazyLoadImage} from "react-lazy-load-image-component";
import image_digital_banking from "./images/send_money_everywhere.svg";
import image_digital_banking_placeholder from "./images/send_money_everywhere.placeholder.svg";
import {AnimatedTitle} from "../../components/title/AnimatedTitle";

export const DigitalBanking = () => {
    const {t} = useTranslation();

    return (
        <div style={
            {
                backgroundColor: COLORS.white
            }
        }>
            <MissionContainer>
                <AnimatedTitle title={t("sections.blog-news.title")} color={COLORS.white}/>
                <br/>
                <ServiceTitleContainer>
                    <TextBlock>
                        <Body color={COLORS.white}>OUR MISSION</Body>
                        <br/>
                        <Headline color={COLORS.white}>The Future of Banking</Headline>
                        <Caption2 color={COLORS.white}>
                            {t("sections.services.introduction")}
                        </Caption2>
                    </TextBlock>
                    <ServiceTitleImageContainer>
                        <
                            LazyLoadImage
                            src={image_digital_banking}
                            alt={'digital banking world map'}
                            effect="fade-in"
                            placeholderSrc={image_digital_banking_placeholder}
                            kil={''}
                        />
                    </ServiceTitleImageContainer>
                </ServiceTitleContainer>
            </MissionContainer>
            <br/>
            <br/>
            <GridContainer>
                <GridItem>
                    <Headline color={COLORS.light}>{t("sections.blog-news.digital-banking.digitalization.title")}</Headline>
                    <br/>
                    <br/>
                    <JustifiedBody
                        color={COLORS.caption2}>{t("sections.blog-news.digital-banking.digitalization.body")}</JustifiedBody>
                </GridItem>
                <GridItem>
                    <Headline color={COLORS.light}>{t("sections.blog-news.digital-banking.rise-of-mobile-money.title")}</Headline>
                    <br/>
                    <br/>
                    <JustifiedBody
                        color={COLORS.caption2}>{t("sections.blog-news.digital-banking.rise-of-mobile-money.body")}</JustifiedBody>
                </GridItem>
                <GridItem>
                    <Headline color={COLORS.light}>{t("sections.blog-news.digital-banking.challenges-and-opportunities.title")}</Headline>
                    <br/>
                    <br/>
                    <JustifiedBody
                        color={COLORS.caption2}>{t("sections.blog-news.digital-banking.challenges-and-opportunities.body")}</JustifiedBody>
                </GridItem>
                <GridItem>
                    <Headline color={COLORS.light}>{t("sections.blog-news.digital-banking.AI.title")}</Headline>
                    <br/>
                    <br/>
                    <JustifiedBody
                        color={COLORS.caption2}>{t("sections.blog-news.digital-banking.AI.body")}</JustifiedBody>
                </GridItem>
            </GridContainer>
        </div>)
}