import styled from "styled-components";

export const ObjectivesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: auto;
`;

export const ObjectivesContent = styled.div`
  width: 100%;
  padding: 0 4rem;
`;

export const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const StyledCol = styled.div`
  width: 50%;
  display: flex;
  text-align: left;
  align-items: center;
`;