import styled from "styled-components";
import {COLORS} from "../../utils/constants";

export const ContactFormContainer = styled.div`
  width: 50%;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const StyledForm = styled.form`
  margin-top: 20px;
  padding-right: 4rem;
`;

export const StyledFormRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;  
  margin: 10px 20px 20px 20px;
`;

export const GroupedButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;  
`;

export const StyledFormNameAndEmail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;  
  margin: 10px 20px 20px 20px;
`;

export const StyledNameAndError = styled.div`
  flex-direction: column;
  padding-right: 10px;
  width: 100%;
`;

export const StyledEmailAndError = styled.div`
  flex-direction: column;
  padding-left: 10px;
  width: 100%;
`;

interface StyledInputProps {
    hasError?: boolean;
    errorMessage?: string;
}

export const StyledFormInput = styled.input<StyledInputProps>`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  color: ${COLORS.white};
  border: ${({ hasError }) => (hasError ? `2px solid ${COLORS.error}` : 'none')};
  transition: 0.3s all;
  border-radius: 4px;
  position: relative;
  background-color: ${COLORS.applePrimary};

  &:focus {
    outline: none;
    background-color: ${COLORS.appleSecondary};
    box-shadow: 0 1px 5px 0 ${COLORS.dark};
  }

  ::placeholder {
    color: ${COLORS.appleSystemBlue};
  }
`;

export const StyledErrorMessage = styled.span`
  color: ${COLORS.error};
  font-size: 14px;
  margin-top: 5px;
`;

interface StyledInputProps {
    hasError?: boolean;
}

export const StyledTextArea = styled.textarea<StyledInputProps>`
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 16px;
  color: ${COLORS.white};
  border: ${({hasError}) => (hasError ? `2px solid ${COLORS.appleSystemRed}` : 'none')}; // Border color is red if hasError is true, no border otherwise
  transition: 0.3s all;
  border-radius: 4px;
  position: relative;
  background-color: ${COLORS.applePrimary};

  &:focus {
    outline: none;
    background-color: ${COLORS.appleSecondary};
    box-shadow: 0 1px 5px 0 ${COLORS.dark};
  }

  ::placeholder {
    color: ${COLORS.appleSystemBlue};
  }
`;