import {useTranslation} from "react-i18next";
import {CenteredContent, WelcomeImage, Overlay, WelcomeContainer, StyledTitle, StyledText} from "./Welcome.Style";
import woman_in_market from "../services/images/service_increase_revenue.jpeg";
import React, {useEffect, useState} from "react";
import {Body} from "../../App.Style";
import {COLORS} from "../../utils/constants";
import {TextBlock} from "../services/Services.Style";
import Typewriter from "typewriter-effect";

export const Welcome = () => {
    const {t} = useTranslation();
    const [index, setIndex] = useState(0);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const strings = [t("sections.welcome.texts.developer.role"), t("sections.welcome.texts.advisor.role"), t("sections.welcome.texts.partner.role")];
    const headlines = [t("sections.welcome.texts.developer.headline"), t("sections.welcome.texts.advisor.headline"), t("sections.welcome.texts.partner.headline")];
    const captions = [t("sections.welcome.texts.developer.body"), t("sections.welcome.texts.advisor.body"), t("sections.welcome.texts.partner.body")];

    useEffect(() => {
        if (timeoutId !== null) {
            clearTimeout(timeoutId);
        }

        const id = setTimeout(() => {
            setIndex((index + 1) % strings.length);
        }, 15000);

        setTimeoutId(id);

        return () => {
            if (timeoutId !== null) {
                clearTimeout(timeoutId);
            }
        };
    }, [index]);


    return (
        <WelcomeContainer>
            <WelcomeImage src={woman_in_market} alt="Woman in market"/>
            <Overlay/>
            <CenteredContent>
                <TextBlock>
                    <Body color={COLORS.primary}>
                        <Typewriter
                            options={{
                                strings: strings[index],
                                autoStart: true,
                                loop: false,
                            }}
                        />
                    </Body>
                    <br/>
                    <StyledTitle color={COLORS.white} key={headlines[index]}>{headlines[index]}</StyledTitle>
                    <StyledText color={COLORS.backgroundWhite} key={captions[index]}>{captions[index]}</StyledText>
                </TextBlock>
            </CenteredContent>
        </WelcomeContainer>
    );
};
