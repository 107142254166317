import {LargeTitle} from "../../App.Style";
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
interface SectionTitleProps {
    title: string;
    color: string;
}

export const AnimatedTitle: React.FC<SectionTitleProps> = ({title, color}) => {
    return (
        <Slide up>
            <Fade>
                <LargeTitle color={color}>{title}</LargeTitle>
            </Fade>
        </Slide>)
};
