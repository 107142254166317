import styled from 'styled-components';
import {COLORS} from "../../utils/constants";

export const NavBar = styled.nav<{ isHome: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 32px;
  background: ${props => props.isHome ? 'none' : 'rgba(0, 0, 0, 0.8)'};
  backdrop-filter: ${props => props.isHome ? 'none' : 'blur(15px)'};
  z-index: 10;
`;
export const StyledNavButton = styled.button<{ isHome: boolean, isSelected: boolean }>`
  border: none;
  color: white;
  background-color: transparent;
  text-shadow: ${props => props.isHome ? '1px 1px 2px rgba(0, 0, 0, 0.3)' : 'none'};
  font-weight: ${props => props.isSelected ? 'bold' : 'normal'};
  font-family: 'SF Pro Text', sans-serif;
  font-size: 12px;
  &:hover {
    color: ${COLORS.primary};
  }
` ;

export const NavLinks = styled.div`
  display: flex;
  gap: 10px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 32px;
`;