import styled, {createGlobalStyle} from "styled-components";

const fontFaces = `
  @font-face {
  @font-face {
    font-family: 'Proxima Nova';
    src: url('/public/fonts/Proxima_Nova_Font.otf') format('opentype'),
    url('/public/fonts/Proxima_Nova_Font_2.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('/public/fonts/SF_Pro_Display_Regular.woff2') format('woff2'),
    url('/public/fonts/SF_Pro_Display_Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('/public/fonts/SF_Pro_Display_Bold.woff2') format('woff2'),
    url('/public/fonts/SF_Pro_Display_Bold.woff') format('woff');    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('/public/fonts/SF_Pro_Text Regular.woff2') format('woff2'),
    url('/public/fonts/SF_Pro_Text Regular.woff') format('woff');    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('/public/fonts/SF_Pro_Text Bold.woff2') format('woff2'),
    url('/public/fonts/SF_Pro_Text Bold.woff') format('woff');    font-weight: bold;
    font-style: normal;
}
`;

export const GlobalStyle = createGlobalStyle`
  ${fontFaces}
`;

interface TextProps {
  color: string;
}

// Large Title Use for large headers at the top of the screen or content section1.e.g. page titles
export const LargeTitle = styled.h1<TextProps>`
  font-family: 'SF Pro Display', sans-serif;
  font-weight: 600;
  font-size: 48px;
  //TODO find where to place this padding
  padding: 0 0 1rem 4rem;
  color: ${(props) => props.color};
`;

// Title 1 Use for primary headings within your content1
export const Title1 = styled.h2<TextProps>`
  font-family: 'SF Pro Display', sans-serif;
  font-weight: normal;
  font-size: 28px;
  color: ${(props) => props.color};
`;

// Title 2 Use for secondary headings, which are less prominent than Title11.
export const Title2 = styled.h3<TextProps>`
  font-family: 'SF Pro Display', sans-serif;
  font-weight: normal;
  font-size: 22px;
  color: ${(props) => props.color};
`;

// Title 3 Use for tertiary headings, smaller than Title2.
export const Title3 = styled.h4<TextProps>`
  font-family: 'SF Pro Display', sans-serif;
  font-weight: normal;
  font-size: 20px;
  color: ${(props) => props.color};
`;

// Headline Use for introducing sections or topics, typically bold for emphasis.
export const Headline = styled.h5<TextProps>`
  font-family: 'SF Pro Display', sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: ${(props) => props.color};
`;

// Body Use for the main bulk of text in your content.
export const Body = styled.p<TextProps>`
  font-family: 'SF Pro Text', sans-serif;
  font-weight: normal;
  font-size: 17px;
  color: ${(props) => props.color};
`;

// Callout Use for text that calls attention to itself, such as highlights or important notes.
export const Callout = styled.span<TextProps>`
  font-family: 'SF Pro Text', sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: ${(props) => props.color};
`;

// Subhead Use for subheadings or subsection titles within your content.
export const Subhead = styled.span<TextProps>`
  font-family: 'SF Pro Text', sans-serif;
  font-weight: normal;
  font-size: 15px;
  color: ${(props) => props.color};
`;

// Footnote Use for ancillary information or citations.
export const Footnote = styled.small<TextProps>`
  font-family: 'SF Pro Text', sans-serif;
  font-weight: normal;
  font-size: 13px;
  color: ${(props) => props.color};
`;

// Caption 1 Used for less important information or to accompany secondary elements on the screen
export const Caption1 = styled.span<TextProps>`
  font-family: 'SF Pro Text', sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: ${(props) => props.color};
`;

// Caption 2 Slightly smaller than Caption 1 and may be used for metadata or timestamps
export const Caption2 = styled.span<TextProps>`
  font-family: 'SF Pro Text', sans-serif;
  font-weight: normal;
  font-size: 17px;
  color: ${(props) => props.color};
`;
