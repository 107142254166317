import styled from 'styled-components';
import {COLORS} from "../../utils/constants";

export const AboutUsBackground = styled.div`
  background-color: ${COLORS.backgroundWhite};
  height: auto;
  width: 100%;
`;

export const AboutUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const AboutUsContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 64px;
  border-radius: 16px;
  background-color: ${COLORS.white};
  margin: 0 64px 64px 64px;
`;

export const StyledAboutUsSectionImage = styled.div`
  padding-left: 20px;
  width: 100%;
  height: 100%;
`;